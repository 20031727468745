<template>
    <div id="waiting-complaints">
        <a-page-header style="border-bottom: 2px solid rgb(235, 237, 240); margin-bottom: 10px;" title="待处理投诉" sub-title="待处理的投诉的列表" />
        <a-modal v-model:visible="showReject" title="不受理原因" @ok="rejectOK" @cancel="rejectCancel" okText="确认" cancelText="取消" :width="1000">
            <a-row>
                <a-col span="4">请填写不受理理由:</a-col>
                <a-col span="16">
                    <a-textarea v-model:value="rejectReason" placeholder="不受理原因" :rows="4" show-count :maxlength="200" />
                </a-col>
                <a-col span="4" style="padding-left: 5px;">
                    单击快速填入
                    <a-button style="margin-bottom: 5px;" @click="rejectReason += '地址不在辖区范围内'">地址不在辖区范围内</a-button>
                    <a-button style="margin-bottom: 5px;" @click="rejectReason += '地址过于模糊，请重新填写提交'">地址过于模糊</a-button>
                    <a-button style="margin-bottom: 5px;" @click="rejectReason += '类别错误，请重新填写提交'">类别错误</a-button>
                </a-col>
            </a-row>
        </a-modal>
        <a-card title="投诉查询" style="text-align: left;">
            <a-row>
                <a-col span="1" class="center" style="padding: 5px;">地址</a-col>
                <a-col span="5" class="center" style="padding: 5px;">
                    <a-input placeholder="请填入查询地址" v-model:value="searchLocation" ></a-input>
                </a-col>
                <a-col span="1" class="center" style="padding: 5px;">日期</a-col>
                <a-col span="5" class="center" style="padding: 5px;">
                    <a-date-picker style="width: 100%;" v-model:value="searchDate" placeholder="请选择日期" />
                </a-col>
                <a-col span="1" class="center" style="padding: 5px;">状态</a-col>
                <a-col span="5" class="center" style="padding: 5px;">
                    <a-cascader v-model:value="searchStatus" :options="statusOptions" placeholder="请设置状态" />
                </a-col>
                <!-- <a-col span="1" class="center" style="padding: 5px;">ID</a-col>
                <a-col span="5" class="center" style="padding: 5px;">
                    <a-input placeholder="请填入查询ID" v-model:value="searchID" ></a-input>
                </a-col> -->
                <a-col span="2"></a-col>
                <a-col span="4" class="center" style="padding: 5px;">
                    <a-button type="primary" @click="get_list">查询</a-button>
                </a-col>
            </a-row>
        </a-card>
        <!-- <a-diviver /> -->
        <a-table :dataSource="data" :columns="cols" :pagination="pagination" @change="table_change" style="margin-top: 10px;">
            <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'category'">
                    <!-- <span v-if="record.category == 1">食品</span>
                    <span v-else-if="record.category == 2">药品</span>
                    <span v-else-if="record.category == 3">计量</span>
                    <span v-else-if="record.category == 4">电梯</span>
                    <span v-else-if="record.category == 5">虚假广告</span> -->
                    {{$store.state.category.category[record.category] ? $store.state.category.category[record.category] : '未分类'}}
                </template>
                <template v-else-if="column.key === 'status'">
                    <span v-if="record.status == 0">不予受理</span>
                    <span v-else-if="record.status == 1">用户撤回</span>
                    <span v-else-if="record.status == 100">已提交</span>
                    <span v-else-if="record.status == 101">已受理</span>
                    <span v-else-if="record.status == 102">已完成</span>
                </template>
                <template v-else-if="column.key === 'action'">
                    <a-button type="primary" @click="toDetail(record.id)" v-if="record.status == 100 || record.status == 101">处理</a-button>
                    <a-button type="primary" @click="toDetail(record.id)" v-else>查看</a-button>
                    <a-divider type="vertical" />
                    <a-button type="danger" @click="onReject(record.id)" v-if="record.status == 100">不受理</a-button>
                    <!-- <a-button type="primary" @click="toDetail(record.id)" v-if="record.status != 100 && record.status != 101">归档</a-button> -->
                </template>
            </template>
        </a-table>
    </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import axios from "axios";
import moment from "moment";
export default {
    methods: { },
    data () {
        return { };
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const pageSize = ref(20);
        const total = ref(100);
        const currentPage = ref(1);
        const pagination = computed(() => ({
            total: total.value,
            current: currentPage.value,
            pageSize: pageSize.value,
        }));
        const cols = ref([
            {
                title: '地址',
                dataIndex: 'location',
                key: 'location'
            },
            {
                title: '类别',
                dataIndex: 'category',
                key: 'category'
            },
            {
                title: '提交时间',
                dataIndex: 'time',
                key: 'time'
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status'
            },
            {
                title: '操作',
                key: 'action'
            }
        ]);

        const data = ref([ ]);

        const showReject = ref(false);
        const rejectReason = ref('');
        const rejectID = ref('');

        const searchLocation = ref('');
        const searchDate = ref(null);
        const searchStatus = ref(null);
        const searchID = ref('');
        const statusOptions = ref([
            {
                value: 0,
                label: '不受理'
            },
            {
                value: 100,
                label: '已提交'
            },
            {
                value: 101,
                label: '已受理'
            },
            {
                value: 102,
                label: '已完成'
            }
        ]);
        const categoryMap = ref(['', '食品', '药品', '计量', '电梯', '虚假广告']);

        function onReject(ID) {
            rejectReason.value = '';
            rejectID.value = ID;
            showReject.value = true;
        }

        function rejectOK() {
            axios
                .post("/api/server/setCompliantStatus", {
                    id: rejectID.value,
                    status: 0,
                    feedback: {
                        title: '举报不受理',
                        content: '不受理原因：' + rejectReason.value
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        message.success('处理成功');
                        showReject.value = false;
                    } else {
                        message.error('处理失败：' + res.data.msg);
                    }
                });
        }

        function rejectCancel() {
            rejectReason.value = '';
            rejectID.value = '';
        }

        function table_change(page) {
            if (page.current == currentPage.value && page.pageSize == pageSize.value) {
                return;
            } else {
                currentPage.value = page.current;
                pageSize.value = page.pageSize;
                get_list();
            }
        }

        function get_list() {
            axios
                .post("api/server/getComplaintsList", {
                    page: currentPage.value,
                    size: pageSize.value,
                    type: 0,
                    location: searchLocation.value != '' ? searchLocation.value : null,
                    time: searchDate.value ? searchDate.value : null,
                    status: searchStatus.value ? searchStatus.value[0] : null,
                    category: store.state.userInfo.userInfo.region == 0 ? null : store.state.userInfo.userInfo.region
                })
                .then((res) => {
                    if (res.data.success) {
                        console.log("success");
                        total.value = res.data.count;
                        data.value = res.data.data;
                    }
                });
        }

        function toDetail(ID) {
            console.log(ID);
            router.push({ name: 'ComplaintDetail', params: {complaint_id: ID} });
        }

        onMounted(() => {
            store.dispatch({ type: 'setSelectedKeys', selectedKeys: ['Complaints'] });
            store.dispatch({ type: 'setOpenKeys', openKeys: ['Manage'] });
            get_list();
        });

        return {
            pageSize,
            total,
            currentPage,
            pagination,
            cols,
            data,
            showReject,
            rejectReason,
            rejectID,
            searchDate,
            searchLocation,
            searchStatus,
            searchID,
            statusOptions,
            categoryMap,
            table_change,
            get_list,
            toDetail,
            onReject,
            rejectOK,
            rejectCancel
        }
    }
}
</script>

<style>
#waiting-complaints {
    width: 100%;
    text-align: center;
}

.rows {
    margin-bottom: 10px;
    height: 30px;
    font-size: 15px;
    border-bottom: 1px solid rgb(235, 237, 240);
}

.center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>
